import React, { useEffect, useState, useMemo } from 'react'
import BasePage from '../../../components/basePage'
import { Button, Text, Flex, Container } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../layout'
import { useQuery } from '@tanstack/react-query'
import {
	addNewMember,
	getBooking,
	getMembers,
	updateMember
} from '../../../services/booking'
import Spinner from '../../../components/spinner'
import moment from 'moment'
import BookingListItem from '../../../components/bookingListItem'
import Modal from '../../../components/modal'
import Input from '../../../components/input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import ErrorStatusModal from '../../../components/errorStatusModal'
import TermsPage from '../../../pagesComponents/login/terms'

const BookingOverview = ({ bookingId }) => {
	const { isLoading, isError, data } = useQuery({
		queryKey: ['booking', bookingId],
		queryFn: () => getBooking(bookingId),
		retry: false
	})

	const [isSubmittingNewMember, setIsSubmittingNewMember] = useState(false)

	const { data: membersData } = useQuery({
		queryKey: ['bookingMembers', bookingId],
		queryFn: () => getMembers(bookingId),
		retry: false
	})

	const [modal, setModal] = useState(false)
	const [errorModal, setErrorModal] = useState(null)
	const [selectedMember, setSelectedMember] = useState(null)

	const resolver = yupResolver(
		object({
			nickname: string()
				.trim()
				.required('Nickname must be set as appears on leaderboards')
				.min(2)
				.max(18)
			// .matches(/^\S*$/, 'The nickname can not contain spaces')
		}).required()
	)
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onChange',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false,
		defaultValues: {
			nickname: ''
		}
	})

	useEffect(() => {
		if (isError) {
			setErrorModal('notFound')
		} else if (data && data.checkinStatus !== 'OK') {
			setErrorModal(data.checkinStatus)
			if (data.outstandingPayment > 0) {
				setErrorModal('outstandingPayment')
			}
		}
	}, [isError, data])

	const title = 'Booking Details'

	const onContinue = async (data) => {
		setIsSubmittingNewMember(true)
		if (modal === 'edit') {
			await updateMember(bookingId, selectedMember.uid, data.nickname)
			if (selectedMember.rfid === null) onLinkRfidCard(selectedMember.uid)
			else setModal(false)
		} else if (modal === 'add') {
			const [newMember] = await addNewMember(bookingId, data.nickname)
			await onLinkRfidCard(newMember.uid)
			setModal(false)
		}

		setIsSubmittingNewMember(false)
	}

	const onLinkRfidCard = async (memberId) => {
		navigate(`/manage-booking/${bookingId}/${memberId}/link-rfid`, {
			state: { rfid: selectedMember?.rfid }
		})
	}

	const showTerms = useMemo(() => {
		return (
			!isLoading &&
			errorModal === 'ok' &&
			data &&
			data.group &&
			!data.group.acceptedTerms
		)
	}, [data, errorModal, isLoading])

	if (isLoading) {
		return (
			<Layout title={title}>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					height="100vh"
				>
					<Spinner />
				</Flex>
			</Layout>
		)
	}

	const modalView = (
		<Modal
			isPink
			opened={modal}
			onClose={() => setModal(false)}
			title={`${modal} name`}
		>
			<Flex flexDir={'column'} gap={5}>
				<Text
					fontSize={16}
					fontWeight={'500'}
					px="20px"
					textAlign="center"
				>
					Please edit your nickname below.
				</Text>
				<Text
					fontSize={16}
					fontWeight={'500'}
					px="20px"
					textAlign="center"
					mb="20px"
				>
					Remember your nickname will appear on your leaderboard, so
					make it memorable and keep it clean!
				</Text>
				<form onSubmit={handleSubmit(onContinue)}>
					<Input
						name="nickname"
						id="nickname"
						type="nickname"
						variant="outline"
						focusBorderColor="transparent"
						borderColor="transparent"
						placeholder={
							selectedMember
								? selectedMember.nickname
								: 'Nickname'
						}
						mb="10px"
						paddingY={8}
						fontSize={20}
						background="#FFFFFF33"
						_placeholder={{ color: '#00000066' }}
						register={register}
					/>

					<Flex justifyContent="center" mb="10px">
						<Text as="span" fontSize="14px" textColor={'red.500'}>
							{errors?.nickname?.message}
						</Text>
					</Flex>
					<Button
						width={'100%'}
						variant="primary"
						type="submit"
						isLoading={isSubmittingNewMember}
					>
						Continue
					</Button>
				</form>
				<Button
					onClick={() => {
						if (selectedMember.rfid)
							onLinkRfidCard(selectedMember.uid)
						else setModal(false)
					}}
					variant="link"
					textColor={'black'}
					fontWeight={'400'}
				>
					{selectedMember?.rfid ? 'Link new games card' : 'Cancel'}
				</Button>
			</Flex>
		</Modal>
	)

	const members = [...(membersData?.members || [])]

	while (members.length < data?.memberCount) {
		members.push({ type: 'awaiting' })
	}

	if (showTerms) {
		return <TermsPage booking={data} />
	}

	return (
		<Layout title={title}>
			<BasePage
				theme="dark"
				fullWidthActionBtns
				largeTitleHeading="Welcome to"
				largeTitle={
					<StaticImage
						width={380}
						alt="Logo"
						style={{ marginBottom: 24, width: '200px' }}
						src="../../images/logo-white.png"
					/>
				}
			>
				<Container
					color={'white'}
					display={'flex'}
					alignItems={'center'}
					flexDir={'column'}
					padding={0}
				>
					<Flex flexDir={'column'} gap={1} alignItems={'center'}>
						<Text variant="ml" color="white" textAlign={'center'}>
							Lead Booker: {data?.leadbookerName}
						</Text>
						<Text variant="ml" color="white" textAlign={'center'}>
							Booking Id: {data?.reservationId}
						</Text>
						<Text variant="ml" color="white" textAlign={'center'}>
							{moment(data?.eventDate).format('DD MMMM yyyy')}
						</Text>
						<Text variant="ml" color="white" textAlign={'center'}>
							Arrival Time: {data?.eventTime}
						</Text>
						<Text variant="ml" color="white" textAlign={'center'}>
							Game Time: {data?.gameTime}
						</Text>
					</Flex>
					{data?.group?.name && (
						<Flex flexDir={'column'} mt={10} gap={1}>
							<Text
								as="h2"
								m={0}
								textAlign={'left'}
								color="white"
							>
								Group Name
							</Text>

							<Container
								variant={'leaderboard'}
								bg={'rgba(255, 255, 255, 0.1)'}
							>
								<Flex
									flex="1"
									direction="column"
									overflow="hidden"
									mx={'2'}
								>
									<Text
										variant={'lg'}
										color={'white'}
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
									>
										{data.group.name.length > 18
											? `${data.group.name.substring(
													0,
													18
											  )}...`
											: data.group.name}
									</Text>
								</Flex>
							</Container>
						</Flex>
					)}

					<Flex flexDir={'column'} mt={10} gap={1}>
						<Text as="h2" m={0} textAlign={'left'} color="white">
							Players
						</Text>
						<Text
							variant="s"
							color="white"
							fontWeight={'400'}
							opacity={0.6}
						>
							Select a tile to add or edit player names
						</Text>
						{members.map((item, index) => {
							const key = `${index}-${item?.groupId}-booking-list-item`
							return (
								<BookingListItem
									onClick={() => {
										if (item.type === 'awaiting') {
											setSelectedMember(null)
											reset({ nickname: '' })
											setModal('add')
										} else {
											setSelectedMember(item)
											setValue('nickname', item.nickname)
											setModal('edit')
										}
									}}
									key={key}
									item={item}
								/>
							)
						})}
					</Flex>
				</Container>
				{modalView}
				<ErrorStatusModal
					setErrorModal={setErrorModal}
					errorModal={errorModal}
				/>
			</BasePage>
			{data?.checkinStatus === 'OK' && (
				<StaticImage
					style={{ width: '100%' }}
					alt="Teddy Bottom"
					src="../../../images/teddy-wedge.png"
				/>
			)}
		</Layout>
	)
}

export default BookingOverview
